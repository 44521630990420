import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Header.css';
import Cookies from 'js-cookie';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";


export default function Header(props) {

    const { username } = props;

    function logout(e) {
        e.preventDefault()
        Cookies.remove("token")
        window.location.replace("/login");
    }

    return (
        <Navbar expand="lg" className="navbar">
            <Navbar.Brand href="/">
                <div style={{ display: "inline" }}>
                    <img className="headerLogo" src="/icons/pfm.png" alt="" />
                    <h2 className="headerHeading">Power Trader</h2>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
                <Nav className="justify-content-end lastUpdateText" style={{ width: "100%" }}>
                    <span>{username ? username : ""}</span>
                </Nav>
            </Navbar.Collapse>

            <Link to="/settings/profile" state={{ component: "profile" }}><button className='headerIcon' style={{ marginLeft: "1rem" }} ><SettingsIcon /></button></Link>
            <button className='headerIcon' onClick={logout} style={{ marginLeft: ".2rem" }} type="submit">Logout</button>
        </Navbar>
    )
}