import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import EmailAlerts from './EmailAlerts'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import './Alert.css';
import AlertMessage from './AlertMessage'

const ssoUrl = "https://onepassport.alpiq.io/login?nocookie&redirect=" + window.location.origin + "/api/auth/ssoHandler"


export default function Alert(props) {

    const { source } = props;

    const filterAliases = {
        "absolute_mw": "energy change MW", "absolute_mwh": "energy change MWh", "absolute_gwh": "energy change GWh",
        "fraction": "percentage of Max capacity", "length_hours": "length in hours",
        "length_days": "length in days", "length_weeks": "length in weeks"
    }
    // const [version, setVersion] = useState();
    const [alerts, setAlerts] = useState([]);
    const [alarmToEdit, setAlarmToEdit] = useState(null);
    const token = Cookies.get('token')
    const [showAddAlertModal, setShowAddAlertModal] = useState(false)

    const [responseMessage, setResponseMessage] = useState("")
    const [responseSuccess, setResponseSuccess] = useState(false)
    const [displayAlertMessage, setDisplayAlertMessage] = useState(false)
    useEffect(() => {
        if (!token) {
            window.location.replace(ssoUrl);
        }
        fetchAlerts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const fetchAlerts = (() => {
        fetch('/api/availabilities/alerts/' + source,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'GET'
            }
        ).then(response => response.json())
            .then(dataDict => {
                setAlerts(dataDict['data'])
            })
    })
    const createAlarm = ((alarm, callback) => {
        fetch('/api/availabilities/alerts/' + source,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'POST',
                body: JSON.stringify(alarm)
            }
        ).then(response => {
            if (response.ok) {
                afterResponse(true, "Alert added successfully", callback)
            } else {
                afterResponse(false, "An error occurred", callback)
            }
            return response.json()
        })
    })

    const editAlarm = ((alarm, callback) => {
        fetch('/api/availabilities/alerts/' + source + '/' + alarmToEdit["_id"],
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'PUT',
                body: JSON.stringify(alarm)
            }
        ).then(response => {
            if (response.ok) {
                afterResponse(true, "Alert modified successfully", callback)
            } else {
                afterResponse(false, "An error occurred", callback)
            }
            return response.json()
        })
    })

    const deleteAlarm = ((id) => {
        fetch('/api/availabilities/alerts/' + source + '/' + id,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'DELETE'
            }
        ).then(response => {
            if (response.ok) {
                afterResponse(true, "Alert deleted successfully")
            } else {
                afterResponse(false, "An error occurred")
            }
            return response.json()
        })
    })


    const submitAlarm = ((alarm, callback) => {
        if (alarmToEdit) {
            editAlarm(alarm, callback)
        } else {
            createAlarm(alarm, callback)
        }
    })

    const onDeleteAlert = ((id) => {
        deleteAlarm(id)
    })

    const onEditAlert = ((alarm) => {
        setAlarmToEdit(alarm)
        setShowAddAlertModal(true)
    })

    const afterResponse = ((success, message, callback) => {
        setResponseMessage(message)
        setResponseSuccess(success)
        setDisplayAlertMessage(true)
        if (callback)
            callback()
        if (success) {
            fetchAlerts()
            setShowAddAlertModal(false)

        }
        setTimeout(
            () => {
                setDisplayAlertMessage(false)
            }, 3000);
    })

    return (
        <div>

            {displayAlertMessage ? <div style={{ zIndex: 9999, position: "absolute", left: 0, right: 0, marginTop: "3em", marginLeft: "auto", marginRight: "auto", width: "fit-content" }}><AlertMessage
                message={responseMessage} success={responseSuccess} /></div> : <></>}

            <Container style={{ backgroundColor: "#1a1a1a", color: "white" }}>
                {alerts ? alerts.map(e => {
                    return (

                        <Row style={{ padding: "1em 0", border: "solid 1px #28282a" }}>
                            <Col> <h6>{e['name']}</h6></Col>
                            <Col>
                                <div style={{ float: 'right' }}>
                                    <Button style={{ marginRight: "0.5em" }} variant="secondary" onClick={() => onEditAlert(e)}>Edit</Button>
                                    <Button variant="secondary" onClick={() => onDeleteAlert(e['_id'])}>Delete</Button>
                                </div>

                            </Col>
                        </Row>
                    )
                }) : null}
                <Row>
                    <Button variant="secondary" style={{ margin: 0 }} onClick={() => { setAlarmToEdit(null); setShowAddAlertModal(!showAddAlertModal) }}>Add</Button>
                </Row>

                {showAddAlertModal ? <EmailAlerts show={showAddAlertModal} onCancel={() => setShowAddAlertModal(false)} onSubmit={submitAlarm} inputAlarm={alarmToEdit} filterAliases={filterAliases} source={source} /> : null}
            </Container>
        </div >
    )
}