import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LoginPage from "./components/Login"
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home"
import { Settings } from "./pages/Settings"

class App extends React.Component {

  constructor() {
    super()
    this.state = { displayOverlay: false, isLoading: true }
  };

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/settings/*" element={<Settings />} />
        </Routes>
      </BrowserRouter>
    )
  }
}
export default App;