import React, { useState, useEffect } from "react";
import Header from '../components/Header'
import Cookies from 'js-cookie';
import './Settings.css';
import { Link, useLocation } from "react-router-dom";
import UsageReport from "../components/UsageReport";
import AppUsageReport from "../components/AppUsageReport";
import Alert from "../components/Alert"
const ssoUrl = "https://onepassport.alpiq.io/login?nocookie&redirect=" + window.location.origin + "/api/auth/ssoHandler"


export function Settings(props) {

    const location = useLocation();

    let components = {
        'profile': <h3>User Profile</h3>,
        'email_alerts_power': <Alert source="power"></Alert>,
        'email_alerts_gas': <Alert source="gas"></Alert>,
        'usage': <UsageReport></UsageReport>,
        'app_usage': <AppUsageReport/>
    }
    let component = location && location.state && location.state.component in components ? components[location.state.component] : null




    const [username, setUsername] = useState()
    const [token,] = useState(Cookies.get('token'))
    const [, setIsLoggedIn] = useState(false)

    useEffect(() => {
        if (!token) {
            fetch("/api/auth/ssoHandler")
        }
        fetch('/api/auth/getUserRoles',
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'GET'
            }
        ).then(response => {
            if (response.status === 200) {
                setIsLoggedIn(true)
                return response.json()
            } else {
                window.location.replace(ssoUrl);
            }
        })
            .then(dataDict => {
                setUsername(dataDict['UserId'])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [token])

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Header username={username} />

            <div className="settingsContent">
                <div className="sidenav">
                    <Link className={location.state && location.state.component === "profile" ? "selected" : ""} to="profile" state={{ component: "profile" }} >
                        <h3>Profile</h3>
                    </Link>

                    <div className="submenu">
                        <h3>Email alerts</h3>
                        <Link className={location.state && location.state.component === "email_alerts_power" ? "selected" : ""} to="email/alerts/power" state={{ component: "email_alerts_power" }} >
                            <h4>Power</h4>
                        </Link>
                        <Link className={location.state && location.state.component === "email_alerts_gas" ? "selected" : ""} to="email/alerts/gas" state={{ component: "email_alerts_gas" }} >
                            <h4>Gas</h4>
                        </Link>
                    </div>

                    {["pawel.stefanski@alpiq.com","paweledward.kasztelan@alpiq.com"].includes(username) ?
                    <>
                        <Link className={location.state && location.state.component === "usage" ? "selected" : ""} to="usage" state={{ component: "usage" }} >
                            <h3>Login metrics</h3>
                        </Link>

                        <Link className={location.state && location.state.component === "app_usage" ? "selected" : ""} to="app_usage" state={{ component: "app_usage" }} >
                            <h3>App Usage</h3>
                        </Link>

                    </>
                        :
                        null}

                    {["paweledward.kasztelan@alpiq.com"].includes(username) ?
                    <>
                    <h3 onClick={()=>fetch(
                        '/api/fundamentals/clearCache',
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token
                            },
                            method: 'GET'
                        }
                    ).then(response => console.log(response))}>Clear cache</h3>
                    </>
                        :
                        null}
                </div>
                <div className="main">
                    {component}
                </div>
            </div>
        </div >
    )
}