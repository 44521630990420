import React from 'react';
import './Header.css';
import Cookies from 'js-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, BarChart, Tooltip, Bar } from 'recharts';



export default function UsageReport() {
    let today = new Date()
    const [token,] = useState(Cookies.get('token'))
    const [data, setData] = useState({})
    const [month, setMonth] = useState(today.getMonth() + 1 + "/" + today.getFullYear())
    const APPS = ["Fundamentals", "GasChart", "GasTable", "PowerChart", "PowerTable"]
    const colors = ["#FF5A5F", "#FFB000", "#00B5AD", "#FC642D", "#007AFF", "#B5008C", "#36C1B3", "#FF4081", "#8ED54E", "#7851A9"]

    const APP_COLOR = {}
    APPS.forEach((a, i) => {
        APP_COLOR[a] = colors[i]
    })

    const onOptionChangeHandler = event => {
        setMonth(event.target.value)
    }

    const sumApps = app =>{
        let total = 0
        APPS.forEach(a =>{
            if(app[a]){
                total = total + app[a]
            }
        })
        return total
    }

    useEffect(() => {
        fetch('/api/auth/usage/appUsageReport',
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'GET'
            }
        ).then(response => {
            return response.json()
        }).then(data => {
            const growth = {}
            const perMonth = {}
            const count = {}
            APPS.forEach(a => {
                count[a] = 0
            })

            data["growth"].forEach(element => {
                const name = element["_id"]["month"] + "/" + element["_id"]["year"]
                const key = element["_id"]["app"]
                const val = element["count"] + count[key]
                if (!growth[name]) {
                    growth[name] = { name: name, [key]: val }
                } else {
                    growth[name][key] = val
                }
                count[key] += element["count"]
            });

            data["growth"].forEach(element => {
                const name = element["_id"]["month"] + "/" + element["_id"]["year"]
                const key = element["_id"]["app"]
                const val = element["count"]
                if (!perMonth[name]) {
                    perMonth[name] = { name: name, [key]: val }
                } else {
                    perMonth[name][key] = val
                }
            });

            // let recent = { month: {}, week: {}, day: {} }

            // data["recentDay"].slice(0, 5).forEach(u=>{
            //     const name = u["user_id"].split('@')[0]
            //     u.apps.forEach(a=>{
            //         if(!recent.day[name]){
            //             recent.day[name] = {name: name, [a.app]:a.count}
            //         }else{
            //             recent.day[name][a.app] = a.count
            //         }
            //     })
            // })

            // data["recentWeek"].slice(0, 5).forEach(u=>{
            //     const name = u["user_id"].split('@')[0]
            //     u.apps.forEach(a=>{
            //         if(!recent.week[name]){
            //             recent.week[name] = {name: name, [a.app]:a.count}
            //         }else{
            //             recent.week[name][a.app] = a.count
            //         }
            //     })
            // })

            // data["recentMonth"].slice(0, 5).forEach(u=>{
            //     const name = u["user_id"].split('@')[0]
            //     u.apps.forEach(a=>{
            //         if(!recent.month[name]){
            //             recent.month[name] = {name: name, [a.app]:a.count}
            //         }else{
            //             recent.month[name][a.app] = a.count
            //         }
            //     })
            // })

            let top = {}
            data["topUsers"].forEach(element => {
                const name = element["month"] + "/" + element["year"]
                if (!top[name]) {
                    top[name] = {}
                }

                element["topUsers"].forEach(e => {
                    const user = e["user_id"].split('@')[0]

                    if (!top[name][user]) {
                        top[name][user] = { "user": user, [e["app"]]: e.count }
                    } else {
                        top[name][user][e["app"]] = e.count
                    }

                })
            })

            const arrTop = {}
            Object.keys(top).forEach(t => {
                arrTop[t] = Object.values(top[t]).sort((a,b)=> sumApps(b) - sumApps(a))
            })

            // const arrRecent = {}
            // Object.keys(recent).forEach(t => {
            //     arrRecent[t] = Object.values(recent[t])
            // })

            // setData({ 'growth': Object.values(growth), 'recent': arrRecent, 'top': arrTop, 'perMonth': Object.values(perMonth) })
            setData({ 'growth': Object.values(growth), 'top': arrTop, 'perMonth': Object.values(perMonth) })
        })
            .catch((error) => {
                console.error('Error:', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // if (data && data.growth && data.recent) {
    if (data && data.growth) {

        return (
            <>
                <Row>
                    <Col>
                        <h3>{"Number of app open over time"}</h3>

                        <ResponsiveContainer width="90%" height={400}>
                            <LineChart data={data.growth}>
                                {APPS.map(key => <Line type="monotone" key={key} dataKey={key} stroke={APP_COLOR[key]} strokeWidth={3} />)}
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="name" />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>

                    <Col>
                        <h3>{"Number of app open per month"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <LineChart data={data.perMonth}>
                                {APPS.map(key => <Line key={key} dataKey={key} stroke={APP_COLOR[key]} strokeWidth={3} />)}
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="name" />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{"Number of app open per user in " + month.split('/')[0].padStart(2, "0") + "/" + month.split('/')[1]}</h3>
                        <select defaultValue={month} onChange={onOptionChangeHandler}>
                            {Object.keys(data.top).map(e => { return <option key={e} value={e}>{e.split('/')[0].padStart(2, "0") + "/" + e.split('/')[1]}</option> })}
                        </select>
                        <ResponsiveContainer width="80%" height={500}>
                            <BarChart data={data.top[month]} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="user" type="category" />
                                <Tooltip />
                                {APPS.map(key => <Bar key={key} dataKey={key} stackId={"a"} fill={APP_COLOR[key]} />)}
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <h3>{"Top 5 most frequent users this month"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <BarChart data={data.recent.month} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="name" type="category" />
                                <Tooltip />
                                {APPS.map(key => <Bar key={key} dataKey={key} stackId={"a"} fill={APP_COLOR[key]} />)}
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>

                    <Col>
                        <h3>{"Top 5 most frequent users this week"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <BarChart data={data.recent.week} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="name" type="category" />
                                <Tooltip />
                                {APPS.map(key => <Bar key={key} dataKey={key} stackId={"a"} fill={APP_COLOR[key]} />)}
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{"Top 5 most frequent users today"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <BarChart data={data.recent.day} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="name" type="category" />
                                <Tooltip />
                                {APPS.map(key => <Bar key={key} dataKey={key} stackId={"a"} fill={APP_COLOR[key]} />)}
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col></Col>
                </Row> */}
            </>
        )
    }
}