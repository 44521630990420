import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useEffect } from "react";
import './EmailAlerts.css'

const ssoUrl = "https://onepassport.alpiq.io/login?nocookie&redirect=" + window.location.origin + "/api/auth/ssoHandler"

export default function EmailAlerts(props) {

  const { source } = props;

  const token = Cookies.get('token')
  const [filters, setFilters] = useState([])
  const [alertFilters, setAlertFilters] = useState([])
  const [alertName, setAlarmName] = useState("")
  const [area, setArea] = useState("")
  const [sector, setSector] = useState("")
  const [period, setPeriod] = useState("")
  const [combineAlerts, setCombineAlerts] = useState(props.inputAlarm ? props.inputAlarm['combineAlerts'] : true)



  const [awaitingResponse, setAwaitingResponse] = useState(false)

  const addFilter = (() => {
    setFilters(filters => [...filters, { type: "", value: "" }])
  })

  const removeFilter = ((index) => {
    if (index > -1) {
      let newFilters = [...filters];
      newFilters.splice(index, 1)
      setFilters(newFilters)
    }

  })

  const OnFilterValueChange = ((e, index) => {
    if (index > -1) {
      let newFilters = [...filters];
      let filter = newFilters[index]
      if (e.target.value === '') {
        filter['value'] = ''
        newFilters[index] = filter
        setFilters(newFilters)
      }
      else {
        if (!isNaN(e.target.value) && e.target.value) {
          filter['value'] = parseFloat(e.target.value)
          newFilters[index] = filter
          setFilters(newFilters)
        }
      }
    }
  })

  const OnFilterTypeChange = ((e, index) => {
    if (index > -1) {
      let newFilters = [...filters];
      let filter = newFilters[index]
      filter['type'] = e.target.value
      newFilters[index] = filter
      setFilters(newFilters)
    }
  })

  const onAlertNameChange = ((e) => {
    setAlarmName(e.target.value)
  })

  const onAreaChange = ((e) => {
    setArea(e.target.value)
  })

  const onSectorChange = ((e) => {
    setSector(e.target.value)
  })

  const onPeriodChange = ((e) => {
    setPeriod(e.target.value)
  })

  const onCombineAlertsChange = ((e) => {
    setCombineAlerts(e.target.checked)
  })


  useEffect(() => {
    if (!token) {
      window.location.replace(ssoUrl);
    }
    fetch('/api/availabilities/alerts/' + source + '/filters',
      {
        headers: {
          'Authorization': 'Bearer ' + token
        },
        method: 'GET'
      }
    ).then(response => response.json())
      .then(dataDict => {
        setAlertFilters(dataDict['data'])
      })
  }, [token, source]);

  useEffect(() => {
    if (props.inputAlarm) {
      setCombineAlerts(props.inputAlarm['combineAlerts'])
      setAlarmName(props.inputAlarm['name']);
      setFilters(props.inputAlarm['filters'])
      setArea(props.inputAlarm['area'])
      setPeriod(props.inputAlarm['period'])
      setSector(props.inputAlarm['sector'])
    }
  }, [props.inputAlarm]);

  const SubmitChanges = (() => {
    if (!alertName || alertName === 'placeholder' || !area || area === 'placeholder' || !sector ||
      sector === 'placeholder' || !period || period === 'placeholder') {
      return
    }
    for (var i = 0; i < filters.length; i++) {
      if (!filters[i]['type'] || filters[i]['type'] === 'placeholder' || !filters[i]['value'])
        return
    }
    setAwaitingResponse(true)
    props.onSubmit({ name: alertName, area, sector, period, filters, combineAlerts }, responseReceived)

  })

  const responseReceived = (() => {
    setAwaitingResponse(false)
  })

  return (
    <>
      <Modal scrollable size="lg" show={props.show} onHide={props.onCancel} className="modal">
        <Modal.Header>
          <Modal.Title>{props.inputAlarm ? "Edit Alert" : "Add New Alert"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Alert Name</Form.Label>
                <Form.Control type="text" placeholder="Enter alert name" value={alertName} onChange={onAlertNameChange} />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Select aria-label="area" value={area} onChange={onAreaChange}>
                  <option value="placeholder">Select Area</option>
                  {"areas" in alertFilters ? alertFilters["areas"].map(e => {
                    return <option value={e}>{e.toUpperCase()}</option>
                  }) : <></>}
                </Form.Select>
              </Col>
              <Col xs={12} md={4}>
                <Form.Select aria-label="sector" value={sector} onChange={onSectorChange}>
                  <option value="placeholder">Select Sector</option>
                  {"sectors" in alertFilters ? alertFilters["sectors"].map(e => {
                    return <option value={e}>{e}</option>
                  }) : <></>}
                </Form.Select>
              </Col>
              <Col xs={12} md={4}>
                <Form.Select aria-label="period" value={period} onChange={onPeriodChange}>
                  <option value="placeholder">Select Period</option>
                  {"periods" in alertFilters ? alertFilters["periods"].map(e => {
                    return <option value={e}>{e}</option>
                  }) : <></>}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <hr style={{
                color: '#c2c2c2',
                borderColor: '#c2c2c2',
                height: '3px',
                margin: "1em 0 1em 0"
              }} />
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Check
                    defaultChecked={combineAlerts}
                    onChange={onCombineAlertsChange}
                    type="switch"
                    id="custom-switch"
                    label="Combine alerts into one email"
                  />
                </Form.Group>
              </Col>
              <hr style={{
                color: '#c2c2c2',
                borderColor: '#c2c2c2',
                height: '3px',
                margin: "1em 0 1em 0"
              }} />
            </Row>
            {filters ? filters.map((e, index) => {
              return (
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Filter type</Form.Label>
                      <Form.Select aria-label="filter" value={e['type']} onChange={(e) => OnFilterTypeChange(e, index)}>
                        <option value="placeholder">Select Type</option>
                        {"filter_types" in alertFilters ? alertFilters["filter_types"].map(e => {
                          let label = props.filterAliases && e in props.filterAliases ? props.filterAliases[e] : e
                          return <option value={e}>{label}</option>
                        }) : <></>}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Filter value</Form.Label>
                      <Form.Control type="text" value={e['value']} onChange={(e) => OnFilterValueChange(e, index)} placeholder="Enter value" />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                      <div>
                        <Button size="sm" variant="outline-light" onClick={() => removeFilter(index)}><DeleteIcon /></Button>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              )
            }) : null}

            <Row>
              <Col xs={12} md={4}>
                <Button variant="secondary" onClick={addFilter}>Add Filter</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={awaitingResponse} size="sm" variant="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button disabled={awaitingResponse} size="sm" variant="primary" onClick={SubmitChanges}>
            {awaitingResponse ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              :
              "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}