import React from 'react';
import './Header.css';
import Cookies from 'js-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, BarChart, Tooltip, Bar } from 'recharts';



export default function UsageReport() {
    let today = new Date()
    const [token,] = useState(Cookies.get('token'))
    const [data, setData] = useState({})
    const [month, setMonth] = useState(today.getMonth() + 1 + "/"+ today.getFullYear())

    const onOptionChangeHandler = event => {
        setMonth(event.target.value)
    }

    useEffect(() => {
        fetch('/api/auth/usage/report',
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'GET'
            }
        ).then(response => {
            return response.json()
        })
            .then(data => {
                let growth = []
                let perMonth = []
                // let count = 1463
                let count = 0
                // let recent = { month: [], week: [], day: [] }
                let top = {}
                data["growth"].forEach(element => {
                    growth.push({ "name": element["_id"]["month"] + "/" + element["_id"]["year"], value: element["count"] + count })
                    count += element["count"]
                });

                data["growth"].forEach(element => {
                    perMonth.push({ "name": element["_id"]["month"] + "/" + element["_id"]["year"], value: element["count"] })
                });

                // data["recentLogins"].slice(0, 5).forEach(element => {
                //     recent.month.push({ "name": element["user_id"].split('@')[0], value: element["monthCount"] })
                //     recent.week.push({ "name": element["user_id"].split('@')[0], value: element["weekCount"] })
                //     recent.day.push({ "name": element["user_id"].split('@')[0], value: element["todayCount"] })
                // });

                data["topUsers"].forEach(element => {
                    top[element["month"]+ "/" + element["year"]] = element["topUsers"].map(e => {
                        return {
                            "user": e["user_id"].split('@')[0],
                            "value": e.count
                        }
                    })

                })

                Object.keys(top).forEach(e =>{
                    top[e] = top[e].sort((a,b) => b.value - a.value)
                })

                // setData({ 'growth': growth, 'recent': recent, 'top': top, 'perMonth': perMonth })
                setData({ 'growth': growth, 'top': top, 'perMonth': perMonth })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (data && data.growth) {

        return (
            <>
                <Row>
                    <Col>
                        <h3>{"Number of logins over time"}</h3>

                        <ResponsiveContainer width="90%" height={400}>
                            <LineChart data={data.growth}>
                                <Line type="monotone" dataKey="value" stroke="#087774" strokeWidth={3}/>
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="name" />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>

                    <Col>
                        <h3>{"Number of logins per month"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <LineChart data={data.perMonth}>
                                <Line dataKey="value" stroke="#087774" strokeWidth={3}/>
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="name" />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{"Number of logins per user in " + month.split('/')[0].padStart(2,"0") + "/" + month.split('/')[1]}</h3>
                        <select defaultValue={month} onChange={onOptionChangeHandler}>
                            {Object.keys(data.top).map(e => {
                                return <option key={e} value={e}>{e.split('/')[0].padStart(2,"0") + "/" + e.split('/')[1]}</option>
                            })}
                        </select>
                        <ResponsiveContainer width="80%" height={500}>
                        <BarChart data={data.top[month]} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="user" type="category" />
                                <Tooltip />
                                <Bar dataKey="value" fill="#087774" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <h3>{"Top 5 most frequent users this month"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <BarChart data={data.recent.month} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="name" type="category" />
                                <Tooltip />
                                <Bar dataKey="value" fill="#087774" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>

                    <Col>
                        <h3>{"Top 5 most frequent users this week"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <BarChart data={data.recent.week} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="name" type="category" />
                                <Tooltip />
                                <Bar dataKey="value" fill="#087774" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{"Top 5 most frequent users today"}</h3>

                        <ResponsiveContainer width="80%" height={400}>
                            <BarChart data={data.recent.day} layout="vertical" margin={{ left: 150 }}>
                                <XAxis type="number" allowDecimals={false} />
                                <YAxis dataKey="name" type="category" />
                                <Tooltip />
                                <Bar dataKey="value" fill="#087774" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col></Col>
                </Row> */}
            </>
        )
    }
}